// src/utils下创建i18n/index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

// 引入各个语言配置文件
import en from './langs/en'
import zh from './langs/zh'

console.log("store.state.currentLang:",store.state.currentLang)

Vue.use(VueI18n)
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
    // 设置默认语言
    locale: localStorage.getItem('language') || 'zh', // 语言标识
    // 添加多语言（每一个语言标示对应一个语言文件）
    messages: {
        en,
        zh
    }
})
// 暴露i18n
export default i18n
