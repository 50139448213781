<template>
  <div class="top-menu-box">
    <div class="logo" @click="$router.push('/')" style="cursor: pointer;display: flex;">
      <img
        :src="$i18n.locale == 'zh' ? ZnlogoSrc : EnglishlogoSrc"
        :class="$i18n.locale == 'zh' ? 'logoStyle' : 'EnglishLogoStyle'"
      />
      <span class="text-font">{{ $t("firstText") }}</span>
    </div>
    <div class="menu-right">
      <div class="top-menu-item" @click="scrollClick('IndexID')">
        {{ $t("firstIndex") }}
      </div>

      <div class="top-menu-item" @click="clickHandle('/price')">
        {{ $t("topMenu.menu03") }}
      </div>

      <!-- 国际化 -->
      <div class="top-menu-item">
        <LanguageDropdown />
      </div>

      <div class="menu-action-box" v-if="!username">
        <div class="top-menu-login" @click="clickHandle('/login')">
          {{ $t("login") }}
        </div>
        <div class="top-menu-login" @click="clickHandle('/register')">
          {{ $t("register") }}
        </div>
      </div>
      <div class="top-menu-item" v-if="username">
        <UserSettingDropdown />
      </div>
    </div>
  </div>
</template>

<script>
import LanguageDropdown from "./LanguageDropdown.vue";
import UserSettingDropdown from "./UserSettingDropdown.vue";

export default {
  components: {
    LanguageDropdown,
    UserSettingDropdown,
  },
  data() {
    return {
      username: "",
      ZnlogoSrc: require("@/assets/code/logo-cn.png"),
      EnglishlogoSrc: require("@/assets/code/logo-en.png"),
      topMenuList: [
        {
          title: "登录",
          url: "/login",
          needquery: true,
        },
        {
          title: "注册",
          url: "/register",
          needquery: true,
        },
        {
          title: "产品/价格",
          url: "/price",
          needquery: false,
        },
      ],
    };
  },

  mounted() {
    this.username = this.$AuthFunc.getUserName();
    // this.$AuthFunc.getUserName()
    // JSON.parse(localStorage.getItem("userInfo") || "{}").username || "";
  },
  methods: {
    clickHandle(url) {
      this.$router.push(url);
      // const baseUrl = window.location.origin; // 获取当前网站的基础URL
      // const fullUrl = `${baseUrl}${url}`;
      //在新窗口打开
      // window.open(fullUrl, "_blank");
    },
    scrollClick(elementId) {
      this.$router.push("/");
      this.$store.commit("setUpdateClickElement", elementId);
    },
  },
};
</script>
<style scoped lang="scss">
.text-font{
  font-size: 14px;
  margin-left: 10px;
  position: relative;
  top: 14px;
}
.link-box {
  display: flex;
  align-items: center;
  // margin-right: 20px;
}
.menu-action-box {
  display: flex;
  .top-menu-login {
    cursor: pointer;
    padding: 0 10px;
    font-size: 16px;
  }
}

.top-menu-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #111111;
  color: #fff;
  height: 80px;
  line-height: 80px;
  font-size: 22px;
  padding: 0 5% 0 20px;

  .menu-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .top-menu-item {
    width: 120px;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: antiquewhite;
    }
  }
}

.logoStyle {
  width: 220px;

  padding: 12px 0 12px 12px;
  height: 55px;
}

.EnglishLogoStyle {
  width: 220px;
  padding: 12px 0 12px 12px;
}
</style>
