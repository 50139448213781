// 模块文件

// 公司简介立",

// AI Model

// AI 对话

// 咖咪咔嘶抖音网址：https://www.douyin.com/user/MS4wLjABAAAAEeetd0gcJ5otMR1N056D9H1oMR-kKPiGLyQtRiMGAsblkD--i1VD9r5Sjvt3izbd

export const LinkTextDataUrl = [
  {
    url: "https://www.youtube.com/channel/UC48xuQoyhOewQTDgwZ7twhg",
    title: "youtube",
  },
  {
    url: "#",
    title: "github",
    icon: require("../assets/icon/github-fill.svg"),
  },
];
//

export const UserLibraryMenuList = [
  {
    title: "用户媒体库",
    titleKey: "MediaLibrary.themeTitle",
    elementId: "UserDataID",
    link: "/user",
    mobileLink: "/mobile/user",
    children: [
      {
        title: "音频数据",
        titleKey: "MediaLibrary.AudioData",
        elementId: "userVoiceID",
        link: "/user/voice",
        mobileLink: "/mobile/user/voice",
      },
      {
        title: "视频数据",
        elementId: "userVideoID",
        titleKey: "MediaLibrary.VideoData",
        link: "/user/video",
        mobileLink: "/mobile/user/video",
      },
      // {
      // title: "图片数据",
      // elementId: "userImageID",
      // titleKey: "MediaLibrary.PictureData",
      // link: "/user/image",
      // mobileLink: "/mobile/user/image",
      // },
    ],
  },
];

// 移动端导航菜单
export const ToolMenuList = [
  {
    title: "AI视频制作",
    titleKey: "siderMenu.Video2Video",
    elementId: "AvatorID",
    link: "/video",
    mobileLink: "/mobile/video",
    children: [
      {
        title: "AI文本",
        titleKey: "siderMenu.AIWritting",
        elementId: "ChatID",
        link: "/talk",
        mobileLink: "/mobile/talk",
      },
      {
        title: "AI语音",
        titleKey: "siderMenu.AIvoice",
        elementId: "VoiceID",
        link: "/voice",
        mobileLink: "/mobile/voice",
      },







      {
        title: "视频制作",
        titleKey: "siderMenu.VideoMake",
        elementId: "VideoID",
        link: "/video/aimake",
        mobileLink: "/mobile/video/aimake",
      },
      // {
      // title: "高级定制",
      // elementId: "AdvancedID",
      // link: "/highlevel",
      // },
    ],
  },
  {
    title: "人物角色定制",
    titleKey: "siderMenu.Customized",
    elementId: "UserID",
    link: "/custommade",
    mobileLink: "/mobile/custommade",
    children: [
      // {
      //   title: "Avatar定制",
      //   titleKey: "siderMenu.AIAvatar",
      //   elementId: "AvatarID",
      //   link: "/video/customavatar",
      //   mobileLink: "/mobile/video/customavatar",
      // },
      // {
      //   title: "AI人物",
      //   titleKey: "siderMenu.AICharacter",
      //   elementId: "AvatorID",
      //   link: "/video/avatarmake",
      //   mobileLink: "/mobile/video/aicharacter",
      // }
    ],
  },
  {
    title: "直播推流",
    titleKey: "siderMenu.LiveStream",
    elementId: "LiveStreamId",
    link: "/live",
    mobileLink: "/live",
    children: [

    ],
  }
];

// 页脚
export const AboutList = [
  {
    title: "关于我们",
    itemList: [
      {
        text: "企业介绍",
        value: "companyIntro",
        url: "/article/company",
        mobileUrl: "/mobile/article/company",
      },
    ],
  },
];

// 直播
