const zh = {
  system: "AI数字人AIGC全栈平台",
  WebName: "工具网站",
  Demo: "AI 视频制作",
  theme: "AI 视频制作",
  TipsText:
    "北京是中华人民共和国的首都，位于中国北部。它是一座有着3000多年历史的文化名城。",
  firstIndex: "首页",
  firstText: "本网站所有内容均人工智能生成",
  language: "简体中文",
  slideModule: {
    warnText: "请先进行滑块校验",
    slideSuccess: "校验通过",
    clickText: "点击按钮进行校验",
    direction: "向右滑动",
  },
  randomModule: {
    randomText: "随机码",
    randomWarn: "请输入4位随机数",
    randomCorrectWarn: "请输入正确的随机码",
  },
  Error: {
    network: "网络连接故障，请稍后重试",
    NoneTigs: "暂无下载任务",
    NoneDownFile: "暂无可保存的资源",
    EnterCopyFile: "请输入需要复制的文本",
    exchangeFile: "请先输入需要转换的文本",
    inputName: "请先填写名称",
    uploadFile: "请先上传文件",
    FailNetwork: "请求失败",
    limitCount: "文件上传不超过5次",
    AudioPermission: "无法获取音频设备",
    Recording: "录音生成中",
    timeout: "网络请求超时,请稍后重试",
    CloneVoiceError: "生成失败,请检查语音类型",
  },
  DialogModel: {
    PublicMediaLibrary: "公共媒体库",
    ChooseTitle: "选择",
    Cancel: "取消",
    Sumit: "确定",
    Userdefined: "用户自定义",
    Preview: "预览",
    CancelPreview: "取消预览",
    exchangeFileSuccess: "转换成功，可进行试听，保存",
  },
  FileAction: {
    Cancel: "删除文件",
    Download: "下载文件",
    Loading: "加载中",
    incorrectType: "上传文件格式错误",
    AllowFile: "只允许上传图片文件!",
    AllowNotImageFile: "只允许上传 PDF、DOC、DOCX、TXT、Excel 或 CSV 文件!",
    limitSize: "上传文件大小不能超过 200MB!",
    limit10MSize: "上传文件大小不能超过 10MB!",
    loginTips: "请先登录",
    TipsTitle: "提示",
    deleteSuccess: "删除成功",
    SaveSuccess: "保存成功",

    ExitPrompt: "检测到该账号已登录，请先退出登录!",
    UserLoggedOut: "用户已退出",
    RequestFailed: "请求失败",
    failDeleted: "删除失败",
    cancelDelete: "取消删除",
    delete: "删除",
    ConfirmationPrompt: "确定要删除文件",
    CustomScene: "定制场景",
    UploadScene: "上传你所需要的场景",
    CopySuccess: "复制成功",
    deleteChatTitle: "删除该对话?",
    deleteWarning: "删除后将无法恢复，请慎重!",
    Confirm: "确定",
    Undelete: "取消",
  },
  Setting: {
    Profile: "账号设置",
    Password: "密码设置",
  },
  Price: {
    Subscription: "订阅",
    Free: "免费(基础版)",
    Pro: "会员(升级版)",
    EnterPrice: "高级(企业版)",
    FreePrice: "免费",
    BuyBtn: "购买",
    FreeInfo: {
      title01: "1 个定制数字人免费版",
      desc01: "每个卡位可修改 1 次",
      title02: "10算力",
      desc02: "每约等于 10 秒视频生成时长",
    },

    ProInfo: {
      title01: "3 个定制数字人免费版",
      desc01: "每个卡位可修改 3 次",
      title02: "3600算力",
      desc02: "每约等于60分钟视频生成时长",
      title03: "1000+公共数字人",
      desc03: "数字人持续更新",
      title04: "150+语音和配音",
      desc04: "语言及配音持续更新",
    }
  },
  priceNew: {
    titleOne: "选择最适合您的计划",
    titleTwo: "AIGC生成,助力您的业务或兴趣",
    monthfee: "按月计费",
    yearfee: "按年计费",
    memberComparison: "会员比较",
    selectPlanOne: {
      titleFont: "免费试用",
      pricedanwei: '¥',
      currencyRMB: {
        RMB: '元',
        USD: '美元'
      },
      priceText: "月",//¥ 0 / 月
      priceYearText: "年",
      anniuBtn: "去试用",

      titleTipOne: "服务提供",
      TipOnedescribe01: "每月1万Tokens",
      TipOnedescribe02: "导出分辨率高达720P",

      titleTipTwo: "包括",
      TipTwoOnedescribe01: "1个克隆语音",
      TipTwoOnedescribe02: "5分钟文本转语音",
      TipTwoOnedescribe03: "1个互动头像",
      TipTwoOnedescribe04: "1个视频模板",
    },
    selectPlanTwo: {
      titleFont: "会员",
      priceText: "月",//"¥ 249 / 月
      priceYearText: "年",
      anniuBtn: "开始使用",

      titleTipOne: "服务提供",
      TipOnedescribe01: "每月100万Tokens",
      TipOnedescribe02: "最长5s头像驱动",
      TipOnedescribe03: "导出分辨率高达1080P",

      titleTipTwo: "包括",
      TipTwoOnedescribe01: "包含免费权益",
      TipTwoOnedescribe02: "3个克隆语音",
      TipTwoOnedescribe03: "3小时文本转语音",
      TipTwoOnedescribe04: "3个自定义视频头像",
      TipTwoOnedescribe05: "3个头像驱动定制",
      TipTwoOnedescribe06: "更快的视频处理",
      TipTwoOnedescribe07: "去除水印",
    },
    selectPlanThree: {
      titleFont: "会员(pro)",
      priceText: "月",//¥ 449 / 月
      priceYearText: "年",
      anniuBtn: "开始使用",

      titleTipOne: "服务提供",
      TipOnedescribe01: "每月300万Tokens",
      TipOnedescribe02: "最长10s头像驱动",
      TipOnedescribe03: "导出分辨率高达1080P",

      titleTipTwo: "包括",
      TipTwoOnedescribe01: "包含会员权益",
      TipTwoOnedescribe02: "10个克隆语音",
      TipTwoOnedescribe03: "10小时文本转语音",
      TipTwoOnedescribe04: "10个自定义视频头像",
      TipTwoOnedescribe05: "10个头像驱动定制",
      TipTwoOnedescribe06: "30+库存头像",
      TipTwoOnedescribe07: "超快的视频处理",
      TipTwoOnedescribe08: "去除水印",
    },
    selectPlanFour: {
      titleFont: "企业",
      priceText: "恰议",
      priceYearText: "恰议",
      anniuBtn: "联系我们",

      titleTipOne: "服务提供",
      TipOnedescribe01: "恰议",

      titleTipTwo: "包括",
      TipTwoOnedescribe01: "包含会员(pro)的所有权益",
      TipTwoOnedescribe02: "翻译校对",
      TipTwoOnedescribe03: "企业安全和隐私",
      TipTwoOnedescribe04: "优先支持",
      TipTwoOnedescribe05: "商业条款",
    },

    tableOne: {
      titleFont: "AI文本",
      columnOneTitle01: "每月Tokens",
      columnOneTitle02: "上传文件数",
      columnOneTitle03: "图片解析",

      columnTwo01: "1万",
      columnTwo02: "小于 1000 字",
      columnTwo03: "无",

      columnThree01: "100万",
      columnThree02: "100万",
      columnThree03: "100次",

      columnFour01: "300万",
      columnFour02: "300万",
      columnFour03: "300次",

      columnFive01: "恰议",
      columnFive02: "恰议",
      columnFive03: "恰议",
    },
    tableTwo: {
      titleFont: "AI语音",
      columnOneTitle01: "每月Tokens",
      columnOneTitle02: "高级文本转语音(Tokens/月)",
      columnOneTitle03: "克隆语音",
      columnOneTitle04: "语音转文本",

      columnTwo01: "1万",
      columnTwo02: "无",
      columnTwo03: "1个",
      columnTwo04: "5分钟",

      columnThree01: "100万",
      columnThree02: "30万",
      columnThree03: "3个",
      columnThree04: "3小时",

      columnFour01: "300万",
      columnFour02: "100万",
      columnFour03: "10个",
      columnFour04: "10小时",

      columnFive01: "恰议",
      columnFive02: "恰议",
      columnFive03: "恰议",
      columnFive04: "恰议",
    },
    tableThree: {
      titleFont: "AI人物及视频制作",
      columnOneTitle01: "AI人物最大时长",
      columnOneTitle02: "AI人物视频生成",
      columnOneTitle03: "视频处理速度(参考)",
      columnOneTitle04: "照片头像",
      columnOneTitle05: "视频模版",
      columnOneTitle06: "自定义视频头像",
      columnOneTitle07: "导出分辨率",
      columnOneTitle08: "删除水印",
      columnOneTitle09: "视频下载",

      columnTwo01: "5s",
      columnTwo02: "1次",
      columnTwo03: "标准",
      columnTwo04: "1个",
      columnTwo05: "1个",
      columnTwo06: "",
      columnTwo07: "720P",

      columnThree01: "5s",
      columnThree02: "5次",
      columnThree03: "快速地",
      columnThree04: "5个",
      columnThree05: "5个",
      columnThree06: "3个",
      columnThree07: "最高1080P",

      columnFour01: "10s",
      columnFour02: "10次",
      columnFour03: "快点",
      columnFour04: "10个",
      columnFour05: "10个",
      columnFour06: "10个",
      columnFour07: "最高1080P",

      columnFive01: "恰议",
      columnFive02: "恰议",
      columnFive03: "最快的",
      columnFive04: "10个+",
      columnFive05: "10个+",
      columnFive06: "10个+",
      columnFive07: "最高4K",
    },
    tableFour: {
      titleFont: "Avatar定制",
      columnOneTitle01: "头像驱动定制数",
      columnOneTitle02: "每个视频最大时长",
      columnOneTitle03: "视频生成次数",
      columnOneTitle04: "图生图",
      columnOneTitle05: "人物换装",
      columnOneTitle06: "姿态动作驱动",

      // columnTwo01:"",
      // columnTwo02:"",
      // columnTwo03:"",
      // columnTwo04:"",
      // columnTwo05:"",
      // columnTwo06:"",

      columnThree01: "3个",
      columnThree02: "5s",
      columnThree03: "5次",
      columnThree04: "50次",
      columnThree05: "30次",
      columnThree06: "10次",

      columnFour01: "10个",
      columnFour02: "10s",
      columnFour03: "10次",
      columnFour04: "100次",
      columnFour05: "50次",
      columnFour06: "30次",

      columnFive01: "恰议",
      columnFive02: "恰议",
      columnFive03: "恰议",
      columnFive04: "恰议",
      columnFive05: "恰议",
      columnFive06: "恰议",
    },
    describeTitle: "常见问题",
    dropDown: {
      titleFontOne: "价格是多少?",
      questionContentOne: "参考上述列表",
      titleFontTwo: "当我取消订阅时，会发生什么?",
      questionContentTwo: "取消订阅即取消了账单周期续订。这意味着在有效账单周期结束之前，您仍可以使用订阅功能。但是，您无需再支付任何续订费用。",
      titleFontThree: "按月订阅和按年订阅有何区别？",
      questionContentThree01: "按月订阅每月从您的付款方式中扣款，而按年订阅每年扣款一次。",
      questionContentThree02: "按年订阅的实际月费低于按月订阅。",
      titleFontFour: "你们提供哪些付款方式?",
      titleFontFour: "你们提供哪些付款方式?",
      questionContentFour: "我们提供:微信，支付宝，Stripe等支付方式",
    },
    freeTrialTitle: "请免费试用",
    PayMentModel: {
      title: "支付",
      ScanCode: "扫码支付",
      payment01: "微信支付",
      payment02: "支付宝支付",
      codeTisi: "二维码 已失效",
      RefreshTxt: "请刷新",
      PaymentAmount: "支付金额",
      numPayunit: "元",
      PaymentMethod: "支付方式"
    }
  },

  topMenu: {
    menu01: "产品",
    menu02: "公司介绍",
    menu03: "价格",
  },
  login: "登录",
  register: "注册",
  logout: "退出",
  siderMenu: {
    Video2Video: "AI视频制作",
    AIWritting: "AI 文本",
    AIvoice: "AI 语音",
    AICharacter: "AI人物",
    VideoMake: "AI视频制作",
    Customized: "人物角色定制",
    AIAvatar: "AI Avatar",
    Live: "直播",
    LiveStream: "直播推流",
  },
  AIWritting: {
    title: "AI 对话 或 写作",
    modelSelect: "选择模型",
    select: "请选择",
    inputText: "输入文字",
    send: "发送",
    firstwords: "你好,有什么可以帮你的么?",
    OpenChat: "开启新对话",
    ContinueChat: "继续对话",
    NewConversation: "新对话",
    Tips: "大模型 也可能会犯错,请核查重要信息",
  },
  AIVoice: {
    ThemeTitle: "语音制作",
    TexttoSpeech: "文本转语音",
    NoneTigs: "暂无自定义语音，请先去右侧进行克隆定制",
    NoneMobileTips: "暂无自定义语音，请先去下方进行克隆定制",
    VoiceSelection: "语音选择",
    CustomVoice: "自定义语音",
    SelectVoice: "请选择合适的语音",
    EnterContent: "请输入内容",
    ClickConversion: "一键转换",
    Audition: "试听",
    Listening: "试听中",
    Download: "下载",
    CloudStorage: "云存储",
    CloneVoice: "一键克隆语音",
    GiveName: "请先给文件取个名字吧",
    UploadFile: "上传视频或者语音",
    RapidCloning: "快速克隆",
    SelectTextListen: "请先选择文本，然后试听",
    Samples: "克隆语音案例",
    Male: "男声",
    Female: "女声",
    Original: "原始",
    Cloned: "克隆",
    AnalysisAndTranslation: "视频语音解析和翻译",
    SpeechToText: "语音转文本",
    HereSpeechToText: "这里是语音转成的文本",
    Copy: "一键复制",
    TranslatedText: "翻译文本",
    UploadVoiceBtn: "上传语音",
    OneselfVoice: "克隆我声音",
    Preview: "预览",
    StartBtn: "开始录音",
    StopBtn: "结束录音",
    RandomBtn: "随机生成文案",
    SaveTips: "对转换后的效果满意吗?",
    saveBtn: "保存",
    cancelBtn: "取消",
    UploadTextTigs: "上传一段语音或者视频",
    warnText: "上传30s左右干净的音视频,效果最佳",
    defaultText:
      "北京是‌中华人民共和国的首都，位于中国华北地区,是一个拥有3000多年建城历史的文化名城。",
  },

  AICharacter: {
    title: "AI 人物",
    AvatarTitle: "Avatar制作",
    AiAudio: "音频定制",
    ImportVoice: "导入语音",
    UploadVoice: "上传语音",
    UploadVoiceText: "上传一段语音",
    FaceText: "上传一张含人脸的图片",
    EmptyTips: "暂无音频,请先进行音频定制操作",
    SelectAvatar: "选择Avatar",
    CustomAvatar: "定制Avatar",
    PlaceholderText: "请选择合适的语音",
    ClickBtn: "点击选择",
    ShowTips: "仅限5秒视频，您可以定制更长的语音视频在",
    GenerationClick: "一键生成",
    DeleteFile: "删除",
    DownLoadFile: "下载",
    SaveFileBtn: "云存储",
    modelName: "'AI视频'模块",
  },

  AIVideoModel: {
    title: "Ai 视频制作",
    AiAudio: "音频定制",
    ImportVoice: "导入语音",
    UploadVoice: "上传语音",
    SelectVideo: "选择视频",
    CustomizedVideo: "自定义视频",
    chooseBtn: "选择视频",
    UploadText: "上传一段视频",
    UploadVideoText: "上传一段视频",
    ShowTips: "您的视频将在这里展示，请点击下方按钮进行生成",
    GenerationClick: "一键生成",
    DeleteFile: "删除",
    DownLoadFile: "下载",
    SaveFileBtn: "云存储",
  },

  LoginModel: {
    EmailLogin: "邮箱登录",
    PhoneLogin: "手机号登录",
    UsernameOrEmail: "邮箱",
    password: "密码",
    Submit: "提交",
    Reset: "重置",
    PhoneNumber: "手机号码",
    VerificationCode: "验证码",
    Sendcode: "发送验证码",
    GoRegister: "去注册",
    Forgotpassword: "忘记密码",
    Goback: "返回首页",
    passwordTigs: "请输入密码",
    phoneTigs: "请输入手机号",
    codeTigs: "请输入验证码",
    userTigs: "请输入用户名",
    sendTigs: "发送失败",
  },
  RegisterModel: {
    EmailRegister: "邮箱注册",
    placeholderText: "请输入邮箱",
    Username: "用户名",
    password: "密码",
    PhoneRegister: "手机号注册",
    PhoneNumber: "手机号码",
    VerificationCode: "验证码",
    Sendcode: "发送验证码",
    Register: "注册",
    Reset: "重置",
    GoLogin: "去登录",
    Goback: "返回首页",
    CorrectEmailFormat: "请输入正确的邮箱格式!",
    passwordTigs: "请输入密码",
    phoneTigs: "请输入手机号",
    codeTigs: "请输入验证码",
    userTigs: "请输入用户名",
    sendTigs: "发送失败",
    successTigs: "注册成功!",
  },

  FindPassModel: {
    themeTitle: "忘记密码",
    Email: "邮箱",
    SendCode: "发送验证码",
    VerificationCode: "验证码",
    NewPassword: "新密码",
    ConfirmPassword: "确认密码",
    Submit: "确定",
    Reset: "重置",
    EnterEmailText: "请输入邮箱",
    CorrectEmailFormat: "请输入正确的邮箱格式!",
    passwordTigs: "请输入密码",
    NewPasswordTigs: "请输入确认密码",
    codeTigs: "请输入验证码",
    GoLogin: "去登录",
    Goback: "返回首页",
    SuccessfulSetting: "设置成功,重新登录",
  },
  MediaLibrary: {
    themeTitle: "用户媒体库",
    AudioData: "音频数据",
    VideoData: "视频数据",
    PictureData: "图片数据",
  },

  full: "全屏显示",
  account: "我的账户",
  invoice: "原始单据",
  reconciliation: "财务对账",
  record: "对账记录",
  report: "月结报表",
  setting: "系统设置",

  tips: "{name} 用户名和密码随便填 {cont}",
  administrator: "管理员",
  placeUser: "请输入用户名",
  palcePass: "请输入密码",
  palceCode: "请输入验证码",
  accounts: "账号",
  password: "密码",
  code: "验证码",
  footer: {
    CompanyProfile: "公司简介",
    introduction: "公司介绍",
    UserAgreement: "用户协议",
    Privacy: "隐私协议",
    Aboutus: "关于我们",
    ContactUs: "联系我们",
    CustomerService: "客服",
    TechnicalSupport: "技术支持",
  },

  Table: {
    View: "查看",
    Download: "下载",
    Delete: "删除",
    Preview: "预览",
    Time: "时间",
    label: "项目",
    Operation: "操作",
    ImageToVideoTitle: "AI视频记录",
    VideoHistoryTitle: "视频生成记录",
    ImageHistoryTitle: "图片管理",
    VoiceCloneHistoryTitle: "克隆语音数据",
    VoiceName: "名字",
    TTSSpeech: "TTS语音",
    Playing: "播放中",
    Play: "播放",
    NoMoreData: "暂无数据",
  },
  Company: {
    title: "公司介绍",
    desc01:
      "咖咪咔嘶（太仓）科技有限公司是姑苏科技创业领军企业，太仓科技创业领军企业，江苏省科技中小型企业，江苏省民营科技企业，英伟达 Inception初创加速计划正式会员。",
    desc02:
      "由资深影视制作人顾星、资深人工智能专家陈平及其他创业团队成员于 2022 年 7 月创立，目标聚焦垂类（影视制作）数字人及其 AIGC 的研发和产业化。公司主营业务为孵化及打造 AIGC 的 IP 作品及数字人偶像艺人。辅营业务则向企业及个人提供交互数字人创设及应用、运营等服务。 公司目标成为国内领先的 AI 数字人开发和运营公司。",
    desc03: "",
    desc04: "",
  },
  AccountSetting: {
    title: "账号设置",
    ChangeUserName: "修改用户名",
    UserName: "用户名",
    NewName: "新用户名",
    newnameWarning: "新用户名不能为空",
    Submit: "提交",
    Reset: "重置",
    ChangeEmail: "修改邮箱",
    Email: "邮箱",
    NewEmail: "新邮箱",
    SendCode: "发送验证码",
  },
};
export default zh;
